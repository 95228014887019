const vehicleFields = ['Year', 'Make', 'Model'];

export const updateVehicleVisualization = ({
  autoSyncVisualizer,
  autoSyncConfig = {},
  vehicleString,
  wheelMpn,
}) => {
  if (!vehicleString) {
    return;
  }

  const autoSyncVisualizerInstance =
    autoSyncVisualizer ??
    window.initVvsExpress?.({
      apiUrl: 'https://api.autosyncstudio.com/',
      apiKey: 'CWTq2EZjCogVAcaPl9WJ',
      ...autoSyncConfig,
    });

  if (!autoSyncVisualizerInstance) {
    return;
  }

  autoSyncVisualizerInstance
    .loadVehicleByDescription(vehicleString)
    .then(() => {
      window.document.body.classList.add(`${autoSyncConfig.elId}__vehicle-visualized`);
      window.document.body.classList.remove(`${autoSyncConfig.elId}__vehicle-failed`);
    })
    .catch((err) => {
      window.document.body.classList.add(`${autoSyncConfig.elId}__vehicle-failed`);
      window.document.body.classList.remove(`${autoSyncConfig.elId}__vehicle-visualized`);

      console.warn(`Failed to visualize the '${vehicleString}' vehicle. ${err.message}`);
    });

  if (wheelMpn) {
    autoSyncVisualizerInstance
      .loadWheelByPn(wheelMpn)
      .then(() => {
        window.document.body.classList.add(`${autoSyncConfig.elId}__wheel-visualized`);
        window.document.body.classList.remove(`${autoSyncConfig.elId}__wheel-failed`);
      })
      .catch((err) => {
        window.document.body.classList.add(`${autoSyncConfig.elId}__wheel-failed`);
        window.document.body.classList.remove(`${autoSyncConfig.elId}__wheel-visualized`);

        console.warn(`Failed to visualize the '${wheelMpn}' wheel. ${err.message}`);
      });
  }

  return autoSyncVisualizerInstance;
};

export const getVehicleString = () => {
  const vehicle = window.Convermax.getVehicle();

  const isVehicleSelected = Object.keys(vehicle).length;
  if (!isVehicleSelected) {
    return null;
  }

  return vehicleFields
    .map((field) => vehicle[field])
    .filter(Boolean)
    .join(' ');
};

globalThis.Convermax.openVehicleWheelDialog = (wheelMpn) => {
  [...window.document.body.classList]
    .filter(
      (cl) => cl.startsWith('cm_vehicle-wheel') && (cl.endsWith('-visualized') || cl.endsWith('-failed')),
    )
    .forEach((className) => {
      window.document.body.classList.remove(className);
    });

  const vehicleString = getVehicleString();
  if (!vehicleString) {
    return;
  }

  const dialogButton = window.document.querySelector('#cm_VehicleWheelDialogButton .cm_button');
  if (!dialogButton) {
    return;
  }
  dialogButton.click();

  setTimeout(() => {
    updateVehicleVisualization({
      autoSyncConfig: {
        elId: 'cm_vehicle-wheel-visualization',
        height: window.innerWidth > 1024 ? 'calc(80vh - 80px)' : 'calc(100vh - 80px)',
        vehicleImageSize: 2400,
        widthAdjustment: 'scale',
      },
      vehicleString,
      wheelMpn,
    });
  }, 0);
};
