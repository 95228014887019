//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-200:_488,_7196,_1848,_9552,_2536,_4256,_7504,_9824;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-200')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-200', "_488,_7196,_1848,_9552,_2536,_4256,_7504,_9824");
        }
      }catch (ex) {
        console.error(ex);
      }